.root {
  min-width: 100vw;
  min-height: 100vh;
  background-color: var(--color-grey-light-1);
  color: var(--color-grey-dark-1);

  padding: 0 3rem;
}

.content {
  max-width: 70rem;
  margin: 0 auto;
  padding: 5rem 0;
}

.title {
  font-size: var(--font-size-large);
  color: var(--color-grey-dark-1);
  margin-bottom: 1rem;
}

.block {
  display: block;
  padding: 3rem;
  background-color: var(--color-grey-light-3);
  color: var(--color-grey-dark-1);
  font-size: var(--font-size-large);
  border-radius: 5px;
}

.desc {
  font-size: var(--font-size-default);
  margin-bottom: 3rem;
}
