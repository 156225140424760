*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  word-break: inherit;
}

html {
  // scenario-editor の値に影響を受けないよう important
  font-size: 62.5% !important; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    font-size: 56.25% !important; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    font-size: 50% !important; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75% !important; //1rem = 12x, 12/16 = 75%
  }
}

body {
  box-sizing: border-box;
  word-break: break-all;
  min-width: 100vw;
  min-height: 100vh;

  * {
    // scrollbar をデフォルトで表示しない (https://www.yoheim.net/blog.php?q=20190801)
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari 対応 */
    }
  }
}

// material-ui の MuiToolbar と同等の値
:root {
  --header-height: 64px;

  @include respond(phone) {
    --header-height: 56px;
  }
}
