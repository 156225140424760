// GENOVA用 font-size 拡大比率
$FONT_SIZE_SCALE_UP_RATIO: 1;
// GENOVA用 font-size 拡大に対応したhelper rem の最大値
$MAX_SCALABLE_REM: 1000;

:root {
  --font-size-scale-up-ratio: $FONT_SIZE_SCALE_UP_RATIO;

  /* COLOR */
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: rgb(103, 103, 103);
  --color-grey-dark-3: #999;

  --color-primary: #3f51b5;
  --color-secondary: rgb(220, 0, 78);
  --color-tertiary: #66bb6a;

  --color-primary-transparent: #3f51b57a;

  --color-primary-light-1: #f8f9fb;
  --color-primary-light-2: #a9b0da;
  --color-primary-light-3: #727fca;

  --color-secondary-light-1: rgb(252, 241, 245);
  --color-secondary-light-2: rgb(245, 124, 166);
  --color-secondary-light-3: rgb(235, 53, 117);

  --color-tertiary-dark-2: #00614a;
  --color-tertiary-dark-3: #008466;

  /* FONT SIZE */
  --font-size-x-large: #{3rem * $FONT_SIZE_SCALE_UP_RATIO};
  --font-size-large: #{2rem * $FONT_SIZE_SCALE_UP_RATIO};
  --font-size-default: #{1.6rem * $FONT_SIZE_SCALE_UP_RATIO};
  --font-size-medium: #{1.4rem * $FONT_SIZE_SCALE_UP_RATIO};
  --font-size-small: #{1.2rem * $FONT_SIZE_SCALE_UP_RATIO};
  --font-size-x-small: #{1rem * $FONT_SIZE_SCALE_UP_RATIO};

  /* SCALABLE SIZE  --scalable-1 = 0.1rem */
  @for $i from 1 through $MAX_SCALABLE_REM {
    --scalable-#{$i}: #{0.1rem * $i * $FONT_SIZE_SCALE_UP_RATIO};
  }
}

body {
  font-family: sans-serif;
  font-weight: 400;
  font-size: var(--font-size-default);
  line-height: 1.85;
  color: var(--colog-grey-dark-2);
}

// GENOVA用に font-size を scale upした場合、全ての font-weight を boldに
@if $FONT_SIZE_SCALE_UP_RATIO > 1 {
  *,
  *::after,
  *::before {
    font-weight: bold !important;
  }
}
