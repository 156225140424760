.container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color-grey-light-2);
}

.icon {
  width: 10rem;
  height: 10rem;

  & > :nth-of-type(2) {
    animation: shake 3s ease-in-out infinite;
    transform-origin: center;
  }
}

.message {
  color: var(--color-secondary);
  font-size: var(--font-size-large);
}

.descriptionBox {
  padding: 2rem;
}

.description {
  color: var(--color-grey-dark-2);
  text-align: center;
}

@keyframes shake {
  from {
    transform: rotate(-5deg);
  }
  10% {
    transform: rotate(5deg);
  }
  15% {
    transform: rotate(0);
  }
  to {
    transform: rotate(0);
  }
}
